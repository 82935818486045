<template>
  <div>
    <common-header></common-header>
    <div class="warp">
      <div class="form-box" v-if="da.remark != ''">
        <div>
          <div class="title">处理结果</div>
          <div class="remark">{{da.remark}}</div>
        </div>
      </div>
      <div class="form-box">
        <div>
          <div class="title">备注内容</div>
          <textarea placeholder="请输入内容" readonly v-model="da.content"></textarea>
        </div>

        <!-- 上传图片 start -->
        <template  v-if="da.img.length > 0">
          <div class="title">图片</div>
          <div class="imgs-big-box">
            <!-- <div class="img-box" v-for="(item, index) in da.img" :key="index" :style="`background-image:url('${requestImgUrl(item)}')`">
              <img :src="requestImgUrl(item)" :preview="1" alt="">
              <div class="delete-img" @click="da.img.split(index,1)"></div>
            </div> -->
            <div class="img-box" v-for="(item, index) in da.img" :key="index" :style="`background-image:url('${(requestImgUrl(item.url))}')`">
              <img :src="(requestImgUrl(item.url))" :preview="1" alt="">
              <!-- <div class="delete-img" @click="deleteImg(index)"></div> -->
            </div>
          </div>
        </template>
        <!-- 上传图片 end -->
      </div>
    </div>

    <div class="sub-btn jb-r-l" @click="Submit">取消售后</div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, uploadFile } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    return {
      da:{
        content: "",
        img: [],
        remark: '',
      },
    }
  },
  mounted() {
    this.getTSaleServicePage({
      id: this.$route.query.id,
    }).then((res)=> {
      this.da.content = res.data.list[0].remark;
      this.da.img = res.data.list[0].imgJson != '[]' ? JSON.parse(res.data.list[0].imgJson) : [];
      this.da.remark = res.data.list[0].stockRemark != '' ? res.data.list[0].stockRemark : res.data.list[0].departmentRemark; 
    })
  },
  methods: {
    deleteImg(index) {
      this.da.img.splice(index, 1)
    },
    choicePicture(event) {
      this.$Indicator.open({
        text: '上传中...',
        spinnerType: 'snake',
      });
      const files = event.target.files[0];
      uploadFile({
        file: files,
        cb: v => {
          let { da } = this;
          da.img.push(v.fileUri);
          this.da = da; 
          this.$Indicator.close();
          this.$previewRefresh();
        },
      })
    },
    // 提交反馈
    Submit() {
      let { da } = this;
      if (da.content == '') {
        this.$toast('请输入备注内容');
        return false;
      }
      let query = this.$route.query;
      this.postSaleServiceCancel({
        id: query.id,
      }).then((res)=> {
        this.$toast('申请成功');
        setTimeout(()=> {
          this.$router.go(-1);
        },2000)
      })
    },
    ...mapActions('account', [
      'getTSaleServicePage',
      'postSaleServiceCancel',
    ])
  },
}
</script>
<style lang='less' scoped>
.imgs-big-box{
  display: flex;
  margin-top: .4rem;
  .img-box{
    margin-right: .2rem;
  }
}
.delete-img{
  width: .4rem;
  height: .4rem;
  border-radius: 50%;
  background: #ff7141;
  position: absolute;
  top: -.2rem;
  right: -.2rem;
  background-image: url('../../assets/images/img/delete.png');
  background-size: 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.img-box{
  position: relative;
  width: 1.9rem;
  height: 1.9rem;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  img{
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
.up-img-box{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .picture{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .icon{
    width: .41rem;
    height: .36rem;
  }
}
.up-img-box{
  width: 1.05rem;
  height: 1.05rem;
  background: #f7f7f7;
  border-radius: .05rem;
}
.title{
  font-size: .28rem;
  color: #262626;
  margin-bottom: .27rem;
}
.sub-btn{
  width: 4.8rem;
  height: .85rem;
  line-height: .85rem;
  text-align: center;
  border-radius: .42rem;
  font-size: .34rem;
  color: #ffffff;
  margin: 1.3rem auto 0;
}
.form-box{
  padding: .2rem;
  background: #fff;
  margin-top: .2rem;
  .item{
    display: flex;
    align-items: center;
    &.ft{
      margin-top: .3rem;
      align-items: flex-start;
    }
  }
  input{
    flex: 1;
    line-height: .9rem;
    border-bottom: 1px solid #f7f7f7;
  }
  .remark{
    background: #f7f7f7;
    border-radius: .05rem;
    font-size: 0.3rem;
    padding: .2rem;
  }
  textarea{
    width: 100%;
    background: #f7f7f7;
    border-radius: .05rem;
    height: 2.3rem;
    padding: .2rem;
    margin-bottom: .45rem;
    font-size: 0.3rem;
  }
  label{
    width: 2.2rem;
    display: block;
  }
}
</style>